import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import '../legal/legal.css'

function Rules() {
    return (  
        <div className="generall_page_wrapper">
                <div className="main-content">
                <div className="any-page"><Header/></div>
                <div className="container doc-style">
                    <p className="doc-title_main">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</p>
                    Настоящее Соглашение заключается между Баруздиным Дмитрием Эдуардовичем, в дальнейшем именуемым «Владелец», и любым лицом, становящимся после принятия условий данного соглашения пользователем сайта, расположенного в сети Интернет по адресу:  https://baruzdin-tri.ru (далее – Сайт), в дальнейшем именуемым «Пользователь», вместе по тексту Соглашения именуемые «Стороны», а по отдельности – «Сторона».<br/>
                    В соответствии со статьей 435 Гражданского кодекса РФ настоящее Пользовательское соглашение признается офертой.<br/>
                    В соответствии со статьей 438 Гражданского кодекса РФ безусловным принятием (акцептом) условий настоящего Соглашения считается факт посещения и использования Сайта (просмотр страниц, чтение материалов, заполнение электронных форм и т.д.).<br/>
                    Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не требует двустороннего подписания и действительно в электронном виде.<br/><br/>
                    <p className='doc-title'>1. Термины и определения, используемые в Соглашении</p>
                    В Соглашении, если из текста Соглашения прямо не вытекает иное, следующие слова и выражения будут иметь указанные ниже значения:<br/>
                    1.1. Сайт – совокупность программных и аппаратных средств для ЭВМ, обеспечивающих публикацию для всеобщего обозрения информации и данных, объединенных общим целевым назначением, посредством технических средств, применяемых для связи между ЭВМ в сети Интернет. Сайт находится в сети Интернет по адресу: https://baruzdin-tri.ru.<br/>
                    1.2. Владелец Сайта – Баруздин Дмитрий Эдуардович.<br/>
                    1.3. Контентом Сайта признаются все объекты, размещенные на Сайте, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их подборки. Владелец Сайта является обладателем исключительных прав на использование Сайта, включая весь контент Сайта.<br/>
                    1.4. Пользователь – пользователь сети Интернет, в частности Сайта.<br/><br/>
                    <p className='doc-title'>2. Предмет Соглашения</p>
                    2.1. Предметом настоящего Соглашения является предоставление Пользователю Сайта доступа к информации об оказываемых Владельцем Сайта услугах. При этом обязательным условием оказания Владельцем Сайта услуг в соответствии с настоящим Соглашением является принятие, соблюдение Пользователем и применение к отношениям Сторон требований и положений, определенных настоящим Соглашением.<br/>
                    2.1.1. Сайт предоставляет Пользователю следующие виды услуг (сервисов):<br/>
                    -	доступ к средствам поиска и навигации Сайта;<br/>
                    -	доступ к информации об услугах и к информации об оказании услуг Владельцем Сайта на платной основе;<br/>
                    -	иные виды услуг (сервисов), реализуемые Владельцем Сайта, указанных на страницах Сайта.<br/>
                    2.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы), а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Сайта.<br/>
                    2.2. Доступ к Сайту предоставляется на безвозмездной основе.<br/>
                    2.3. Владелец Сайта оставляет за собой право изменять условия настоящего Соглашения и всех его неотъемлемых частей без согласования с Пользователем с уведомлением последнего посредством размещения на Сайте новой редакции Соглашения или какой-либо его неотъемлемой части, подвергшейся изменениям. Новая редакция Соглашения и/или какой-либо его неотъемлемой части вступает в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен Владельцем Сайта при их опубликовании. Действующая редакция Соглашения и всех приложений к нему всегда находится на Сайте в публичном доступе по адресу: https://baruzdin-tri.ru.<br/><br/>
                    <p className='doc-title'>3. Права и обязанности Владельца Сайта</p>
                    3.1. Владелец Сайта обязуется:<br/>
                    3.1.1. Оказывать Пользователю услуги, указанные в пункте 2.1 настоящего Соглашения. <br/>
                    3.1.2. Обеспечивать круглосуточную доступность сервера, на котором расположен Сайт, за исключением времени проведения профилактических работ.<br/>
                    3.2. Владелец Сайта имеет право:<br/>
                    3.2.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта в одностороннем порядке. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.<br/>
                    3.2.2. Распоряжаться статистической информацией, связанной с функционированием Сайта.<br/><br/>
                    <p className='doc-title'>4. Права и обязанности Пользователя</p>
                    4.1. Пользователь обязуется:<br/>
                    4.1.1. Полностью ознакомиться с условиями настоящего Соглашения до момента регистрации на Сайте.<br/>
                    4.1.2. Соблюдать все условия настоящего Соглашения.<br/>
                    4.1.3. Соблюдать имущественные и неимущественные права Владельца Сайта и иных правообладателей при использовании Сайта.<br/>
                    4.1.4. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта. Не использовать программное обеспечение и не осуществлять действия, направленные на нарушение нормального функционирования Сайта и его сервисов не распространять или иным образом использовать вирусы, трояны и другие вредоносные программы; не использовать без специального на то разрешения Владельца Сайта автоматизированные скрипты (программы) для сбора информации на Сайте и (или) взаимодействия с Сайтом и его сервисами.<br/>
                    4.2. Пользователю запрещено:<br/>
                    4.2.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта;<br/>
                    4.2.2. Нарушать надлежащее функционирование Сайта;<br/>
                    4.2.3. Осуществлять доступ к каким-либо услугам иным способом, кроме как через интерфейс, предоставленный Владельцем Сайта, за исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии с отдельным соглашением с Владельцем Сайта.<br/>
                    4.2.4. Воспроизводить, дублировать, копировать, продавать, осуществлять торговые операции и перепродавать услуги для каких-либо целей, за исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии с условиями отдельного соглашения с Владельцем Сайта.<br/>
                    4.3. Пользователь имеет право:<br/>
                    4.3.1. Круглосуточно получать доступ к серверу, на котором расположен Сайт, за исключением времени проведения профилактических работ.<br/>
                    4.3.2. Используя функционал Сайта, делать запросы на возможное оказание определенных услуг, размещенных на Сайте.<br/>
                    4.3.3. Задавать любые вопросы, относящиеся к услугам, размещенным на Сайте. Контактная информация размещена на Сайте.<br/>
                    4.4. Пользователь согласен с тем, что, осуществляя доступ к Сайту и пользуясь его контентом, он:<br/>
                    4.4.1. Выражает свое безоговорочное согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать или прекратить использование Сайта.<br/>
                    4.4.2. Получает личное неисключительное право использовать контент Сайта на любом устройстве при условии, что ни сам Пользователь, ни любые иные лица при содействии Пользователя не будут копировать или изменять программное обеспечение; создавать программы, производные от программного обеспечения; проникать в программное обеспечение с целью получения кодов программ; осуществлять продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав в отношении программного обеспечения сервисов, предоставляемых Сайтом.<br/>
                    4.4.3. В целях реализации настоящего Соглашения Пользователи дают Владельцу Сайта разрешение на использование, хранение, обработку и распространение персональных данных тем способом и в той мере, в которой это необходимо для исполнения условий настоящего Соглашения. Порядок использования, хранения, обработки и распространения персональных данных Пользователей размещен на Сайте по адресу:  https://baruzdin.online/privacy.<br/><br/>
                    <p className='doc-title'>5. Ответственность Сторон</p>
                    5.1. Владелец Сайта не гарантирует, что программное обеспечение Сайта не содержит ошибок и/или компьютерных вирусов или посторонних фрагментов кода. Владелец Сайта предоставляет возможность Пользователю пользоваться программным обеспечением Сайта «как оно есть», без каких-либо гарантий со стороны Владельца Сайта.<br/>
                    5.2. Владелец Сайта прилагает все возможные усилия для обеспечения нормальной работоспособности Сайта, однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению, а также возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:<br/>
                    •	неправомерных действий Пользователей, направленных на нарушения информационной безопасности или нормального функционирования Сайта;<br/>
                    •	сбоев в работе Сайта, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении Сайта;<br/>
                    •	отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между сервером Пользователя и сервером Сайта;<br/>
                    •	проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках Системы оперативно-разыскных мероприятий;<br/>
                    •	установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения;<br/>
                    •	других случаев, связанных с действиями (бездействием) Пользователей и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования, существовавшей на момент заключения Соглашения, а также любых других действий, направленных на Сайт и на третьих лиц;<br/>
                    •	выполнения работ, указанных в пунктах 6.3 и 6.4 настоящего Соглашения.<br/>
                    5.3. Владелец Сайта имеет право производить профилактические работы в программно-аппаратном комплексе Сайта с временным приостановлением работы Сайта по возможности в ночное время и максимально сокращая время неработоспособности Сайта, уведомляя об этом Пользователя, если технически это представляется возможным.<br/>
                    5.4. В случае наступления форс-мажорных обстоятельств, а также аварий или сбоев в программно-аппаратных комплексах третьих лиц, сотрудничающих с Владельцем Сайта, или действий (бездействия) третьих лиц, направленных на приостановку или прекращение функционирования Сайта, возможна приостановка работы Сайта без предварительного уведомления Пользователя.<br/>
                    5.5. Владелец Сайта не несет ответственности за нарушение Пользователем настоящих Правил. <br/>
                    5.6. Ни одна из Сторон не несет ответственности за полное или частичное неисполнение любой из своих обязанностей, если неисполнение является следствием таких обстоятельств, как наводнение, пожар, землетрясение, другие стихийные бедствия, война или военные действия и другие обстоятельства непреодолимой силы, возникшие после заключения Соглашения и не зависящие от воли Сторон.<br/><br/>
                    <p className='doc-title'>6. Порядок разрешения споров и урегулирования претензий</p>
                    6.1. В случае возникновения споров между Пользователем и Владельцем Сайта по вопросам, связанным с исполнением Соглашения, Стороны примут все меры к разрешению их путем переговоров между собой. Претензионный порядок разрешения споров обязателен. Претензии Пользователей по предоставляемым Услугам принимаются и рассматриваются Владельцем Сайта только в письменном виде и в порядке, предусмотренном настоящим Соглашением и действующим законодательством РФ.<br/>
                    6.2. Для разрешения споров, возникших между Пользователем и Владельцем Сайта в результате использования услуг, применяется следующий претензионный порядок. Пользователь, считающий, что его права нарушены из-за действий Владельца Сайта, направляет последнему претензию, содержащую суть предъявляемого требования, обоснование его предъявления, а также все данные Пользователя. Претензия также направляется Владельцу Сайта в письменном виде посредством отправки на электронную почту: mail@baruzdin-tri.ru;<br/>
                    •	в течение 5 (пяти) рабочих дней со дня получения претензии Владелец Сайта обязан изложить свою позицию по указанным в ней принципиальным вопросам и направить свой ответ по адресу электронной почты или почтовому адресу, указанному в претензии Пользователя;<br/>
                    •	в случае недостижения разрешения спора путем претензионного порядка спор подлежит рассмотрению в соответствии с пунктом 7.4 Соглашения;<br/>
                    •	Владельцем Сайта не рассматриваются анонимные претензии или претензии, не позволяющие идентифицировать Пользователя.<br/>
                    6.3. Для решения технических вопросов при определении вины Пользователя в результате его неправомерных действий при пользовании сетью Интернет и Сайтом, в частности, Владелец Сайта вправе самостоятельно привлекать компетентные организации в качестве экспертов. В случае установления вины Пользователя последний обязан возместить затраты на проведение экспертизы.<br/>
                    6.4. При недостижении согласия между Сторонами путем переговоров спор, вытекающий из настоящего Соглашения, подлежит рассмотрению в суде общей юрисдикции по месту нахождения Владельца Сайта.<br/><br/>
                    <p className='doc-title'>7. Прочие условия</p>
                    7.1. Настоящее Соглашение вступает в силу с момента акцепта настоящей оферты Пользователем и заключается на неопределенный срок. Акцептом настоящей оферты признается факт посещения и использования Сайта Пользователем (просмотр страниц, чтение материалов, заполнение электронных форм и т.д.).<br/>
                    7.2. Настоящее Соглашение является офертой и в силу действующего гражданского законодательства РФ Владелец Сайта имеет право на отзыв оферты в соответствии со статьей 436 Гражданского кодекса РФ. В случае отзыва настоящего соглашения Владельцем Сайта настоящее соглашение считается прекращенным с момента отзыва. Отзыв осуществляется путем размещения соответствующей информации на Сайте.<br/>
                    7.3. Положения настоящего Соглашения устанавливаются, изменяются и отменяются Владельцем Сайта в одностороннем порядке без предварительного уведомления. С момента размещения на Сайте новой редакции Соглашения предыдущая редакция считается утратившей свою силу. В случае существенного изменения положений настоящего Соглашения Владелец Сайта извещает об этом Пользователей путем размещения на Сайте соответствующего сообщения.<br/>
                    7.4. Если Пользователь не согласен с условиями настоящего Соглашения, то он должен немедленно покинуть Сайт, в противном случае продолжение использования Пользователем Сайта означает, что Пользователь согласен с условиями Соглашения.<br/>
                    7.5. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством РФ.<br/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Rules;