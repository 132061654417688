import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "../legal/legal.css"

function Offer() {
    return (  
        <div className="generall_page_wrapper">
            <div className="main-content">
                <div className="any-page"><Header/></div>
                <div className="container doc-style">
                    <p className="doc-title_main">Публичная оферта</p>
                    Настоящая оферта в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации является официальным предложением (публичной офертой) Баруздина Дмитрия Эдуардовича, именуемого в дальнейшем «Исполнитель», и содержит все существенные условия предоставления услуг.<br/>
                    Настоящая оферта размещена на официальном сайте Исполнителя: https://baruzdin-tri.ru.<br/>
                    Баруздин Дмитрий Эдуардович выставляет настоящую публичную оферту на заключение договора об оказании услуг на нижеследующих условиях.<br/><br/>
                    <p className="doc-title">Определения</p>
                    Сайт – официальный сайт Исполнителя в сети Интернет, расположенный по адресу: https://baruzdin-tri.ru.<br/>
                    Тарифы – тарифы и цены на все Услуги, опубликованные на Сайте.<br/>
                    Услуги – комплекс консультаций, занятий и справочных материалов:<br/>
                    -	План тренировок (Online тренировки) – комплекс спортивных заданий, тренировок и справочных материалов, разработанный Исполнителем индивидуально для каждого Клиента, исходя из физической подготовки, состояния здоровья, возраста, образа жизни Клиента, с учетом пожеланий Клиента относительно целей и периодичности тренировок. Составляются и контролируются Исполнителем дистанционно и реализуются Клиентом самостоятельно.<br/>
                    -	Персональные тренировки – комплекс спортивных заданий и тренировок, разработанный Исполнителем индивидуально для каждого Клиента, исходя из физической подготовки, состояния здоровья, возраста, образа жизни Клиента, с учетом пожеланий Клиента относительно целей и периодичности тренировок. Реализуются очно под контролем Исполнителя.<br/>
                    -	Иные Услуги – иные Услуги, предоставляемые Исполнителем. Описание и Тарифы на иные Услуги размещены на Сайте.<br/>
                    Пакет Услуг – определенный набор Услуг, оказываемых Исполнителем вместе или на определенных условиях. Описание и Тарифы на Пакеты Услуг размещены на Сайте.<br/>
                    Клиент – полностью дееспособное физическое лицо, акцептовавшее условия оферты и оплатившее Услуги.<br/>
                    Исполнитель – Баруздин Дмитрий Эдуардович, физическое лицо, оказывающее Услуги в качестве самозанятого.<br/><br/>
                    <p className="doc-title">1. Порядок получения акцепта настоящей оферты</p>
                    1.1. В соответствии с пунктом 3 статьи 438 ГК РФ в случае согласия дееспособного физического лица с условиями оферты и оплаты услуг указанное лицо становится Клиентом.<br/>
                    1.2. Клиент, заинтересованный в совершении акцепта условий оферты, путем заполнения специальной формы, размещенной на Сайте, направляет заявку на получение обратного звонка от Исполнителя.<br/>
                    1.3. Акцептом настоящей публичной оферты является осуществление Клиентом полной оплаты услуг в соответствии с условиями настоящей оферты. С момента поступления денежных средств на расчетный счет Исполнителя Клиент считается выполнившим свои обязательства по акцепту настоящей оферты. Срок для совершения акцепта оферты Клиентом является неограниченным.<br/>
                    1.4. Осуществляя акцепт настоящей оферты, Клиент подтверждает достоверность своих личных данных и принимает на себя всю ответственность за их точность, полноту и достоверность. Заключая настоящий Договор, Клиент подтверждает, что он не имеет медицинских противопоказаний для следования консультациям Исполнителя и полностью принимает на себя ответственность за состояние своего здоровья.<br/>
                    1.5. Осуществляя акцепт настоящей оферты, Клиент подтверждает, что при дистанционном оказании услуг по настоящему Договору Клиент самостоятельно обеспечивает доступ в Интернет с использованием программного обеспечения, позволяющего получить услуги дистанционным способом, а также по указанию Исполнителя регистрируется на сторонней платформе для получения доступа к Плану тренировок и скачивает мобильные приложения, необходимые для реализации Плана тренировок.<br/><br/>
                    <p className="doc-title">2. Предмет Договора</p>
                    2.1. Исполнитель обязуется оказать лицу, акцептовавшему настоящую оферту, консутационно-тренировочные услуги по вопросам организации тренировочного процесса в различных видах спорта в пределах Пакетов Услуг, размещенных на Сайте, а Клиент обязуется принять и оплатить услуги.<br/>
                    2.2. Договор и дополнения к Договору, а также, информация о видах, пакетах и стоимости Услуг, оказываемых Клиенту, включая подробную информацию о Тарифах, публикуются на Сайте.<br/>
                    2.3. Все материалы, предоставленные Исполнителем и используемые Клиентом в ходе получения Услуг, являются объектами интеллектуальной собственности. Копирование и распространения данных материалов, без письменного согласия Исполнителя, является нарушением закона Российской Федерации об охране интеллектуальной собственности, авторских и смежных прав.<br/>
                    2.4. При заключении Договора Клиент подтверждает, что он не имеет медицинских и иных противопоказаний для занятий спортом и полностью принимает на себя ответственность за состояние своего здоровья и его возможные изменения в процессе реализации выбранных Услуг.<br/>
                    2.5. Исполнитель имеет право изменять и дополнять данный Договор без предварительного согласования с Клиентом, обеспечивая при этом публикацию  изменений и дополнений на Сайте. Клиент самостоятельно осуществляет ознакомление с Договором и всеми изменениями. В случае, если Клиент продолжает пользоваться Услугами после изменения настоящего Договора, это означает, что такие изменения приняты Клиентом.<br/><br/>
                    <p className="doc-title">3. Обязанности сторон</p>
                    3.1. Исполнитель обязан:<br/>
                    3.1.1. Обеспечить надлежащее качество оказываемых по настоящему Договору Услуг при условии исполнения Клиентом обязанностей, предусмотренных настоящим Договором.<br/>
                    3.1.2. Принимать необходимые своевременные меры по предупреждению и регулированию нарушения качества предоставляемых Услуг.<br/>
                    3.1.3. Предоставить Клиенту полную информацию об Услуге, о продолжительности и условиях ее оказания. Указанная информация может размещаться на сайте Исполнителя либо на усмотрение Исполнителя может направляться напрямую Клиенту.<br/>
                    3.1.4. Обеспечить доступ Клиента к отвечающей требованиям безопасности площадке, на которой будут оказываться Услуги в формате Индивидуальных тренировок. О месте проведения Индивидуальной тренировки Исполнитель обязуется заблаговременно уведомить Клиента. По договоренности между Клиентом и Исполнителем Индивидуальная тренировка может быть проведена на площадке, предложенной Клиентом.<br/>
                    3.1.5. Согласовывать даты и время проведения Индивидуальных тренировок, а также их изменения с Клиентом.<br/>
                    3.1.6. Уведомлять Клиента об отмене / переносе Индивидуальной тренировки не позднее чем за 24 часа до предполагаемого начала такой тренировки.<br/>
                    3.1.7. Уведомлять Заказчика об изменениях, касающихся сроков подготовки Плана тренировок.<br/>
                    3.1.8. Обеспечить составление Плана тренировок, подготовку рекомендаций по тренировочному процессу и внесение изменений в План тренировок на основе обработки полученных от Клиента данных, данных, полученных в ходе Персональных тренировок.<br/>
                    3.2. Исполнитель вправе:<br/>
                    3.2.1. В любое время приостановить оказание Услуг по Договору в случае неисполнения Клиентом обязательств по оплате Услуг.<br/>
                    3.2.2. В одностороннем порядке устанавливать и изменять перечень, стоимость, а также порядок оказания Услуг, уведомив Клиента путем размещения соответствующей информации на Сайте либо по номеру телефона, указанному при заполнении специальной формы (см. п. 1.2. Договора).<br/>
                    3.2.3. Отказать Клиенту в проведении Индивидуальной тренировки, если Клиент явился на Индивидуальную тренировку по истечении 20 минут после предполагаемого начала Индивидуальной тренировки, без возврата денежных средств.<br/>
                    3.2.4. Отказать Клиенту в компенсации времени опоздания / продлении Индивидуальной тренировки на время опоздания, если Клиент опоздал на тренировку менее чем на 20 минут.<br/>
                    3.2.5. Отказать Клиенту в переносе Индивидуальной тренировки, если соответствующий запрос поступил от Клиента менее чем за 2 часа до предполагаемого начала Индивидуальной тренировки, без возврата денежных средств.<br/>
                    3.2.6. Отказать Клиенту в оказании Услуг, в случае обнаружения у Клиента противопоказаний к отдельным видам физических нагрузок, без возврата денежных средств.<br/>
                    3.2.7. Отказать Клиенту в посещении Индивидуальной тренировки, в случае появления Заказчика на занятиях в состоянии алкогольного, наркотического или иного опьянения, при наличии остаточного состояния опьянения или без надлежащей спортивной одежды, обуви и другой экипировки либо при наличии экипировки, которая может повлечь нарушение техники безопасности.<br/>
                    3.2.8. Самостоятельно определять способы оказания услуг, в том числе касающиеся методик, способов изложения, а также предоставления обучающих материалов, используемых при проведении Индивидуальной тренировки или при подготовки Плана тренировок.<br/>
                    3.2.9. Пересматривать условия и стоимость услуг. Информация об изменении стоимости размещается на сайте Исполнителя.<br/>
                    3.3. Клиент обязан:<br/>
                    3.3.1. Заполнить анкетные данные, предоставить контактную информацию, сообщить информацию об имеющихся противопоказаниях и врожденных, либо приобретенных травмах, болезнях.<br/>
                    3.3.2. Строго соблюдать рекомендации Исполнителя о порядке и условиях реализации Услуг, интенсивности занятий, комплекса восстановительных мероприятий, иные рекомендации.<br/>
                    3.3.3. Соблюдать санитарно-гигиенические требования, систематически, самостоятельно и ответственно контролировать свое здоровье.<br/>
                    3.3.4. Самостоятельно оценивать адекватность физических нагрузок собственному состоянию здоровья, как во время самостоятельных тренировок, так и во время тренировок с Исполнителем.<br/>
                    3.3.5. Соблюдать меры безопасности, правила дорожного движения и общие правила безопасности, не подвергая опасности здоровье окружающих, во время реализации всех видов Услуг.<br/>
                    3.3.6. При наличии признаков ухудшения состояния здоровья и/или обнаружении признаков заболеваний своевременно и самостоятельно обратиться за оказанием квалифицированной медицинской помощи, а также сообщить об этом Исполнителю любым возможным способом (телефон, смс, электронная почта)<br/>
                    3.3.7.Своевременно направлять Исполнителю данные, необходимые для подготовки и/или корректировки Online тренировок (Плана тренировок) любым доступным способом (электронная почта, специальные сервисы по учету тренировок, смс, телефонные звонки).<br/>
                    3.3.8.Своевременно оплачивать Услуги на условиях Договора.<br/>
                    3.3.9. Соблюдать и поддерживать общественный порядок и общепринятые нормы поведения, вести себя уважительно по отношению к Исполнителю и третьим лицам, не допускать действий, создающих опасность для окружающих.<br/>
                    3.3.10. Во время тренировок строго следовать указаниям Исполнителя, соблюдать рекомендации Исполнителя о продолжительности и интенсивности занятий, технику выполнения упражнений.<br/>
                    3.3.11. Выполнять тренировки в спортивной одежде, обеспечивающей безопасность выполнения упражнений.<br/>
                    3.3.12. При наличии хронических, инфекционных, кожных  заболеваний, психических и психологических расстройств и заболеваний, а также болезней  внутренних органов воздержаться от посещения занятий и/или выполнения упражнений.<br/>
                    3.3.13. В течение срока действия Договора, по мере необходимости, самостоятельно проходить медицинские осмотры для осуществления контроля за состоянием своего здоровья и отсутствием противопоказаний для получения Услуг.<br/>
                    3.3.14. Посещать тренировки согласно расписанию и договоренности с Исполнителем. При опоздании Клиента более чем на 20 минут Исполнитель вправе отказать в проведении Индивидуальной тренировки без возврата денежных средств, уплаченных за тренировку. При опоздании Клиента менее чем 20 минут Исполнитель вправе отказать в продлении Индивидуальной тренировки на время опоздания, а также в компенсации денежных средств пропорциональной времени опоздания.<br/>
                    3.3.15. Своевременно оповещать Исполнителя о переносе / отмене Индивидуальной тренировки. При оповещении Исполнителя менее чем за 2 часа до начала Индивидуальной тренировки Исполнитель вправе отказать в переносе  / отмене Индивидуальной тренировки и удержать денежные средства, уплаченные за тренировку.<br/>
                    3.3.16. Незамедлительно информировать Исполнителя в письменной форме о любых изменениях персональных данных, контактных данных, или обстоятельствах, способных повлиять на надлежащее исполнение взаимных обязательств по Договору.<br/>
                    3.4. Заказчику запрещается:<br/>
                    3.4.1. Находиться при проведении Индивидуальной тренировки в нерекомендованной экипировке, а также в экипировке, которая не соответствует или может поспособствовать нарушению техники безопасности.<br/>
                    3.4.2. Посещать Индивидуальные тренировки и пользоваться иным Услугами при наличии признаков острого или хронического инфекционного и/или кожного заболевания, иного заболевания и (или) расстройства, влияющие на собственное здоровье или здоровье окружающих. В случае невыполнения Клиентом указанного требования, Исполнитель вправе отказать Клиенту в проведении Индивидуальных тренировок  до полного выздоровления.к<br/>
                    3.4.3. Употреблять в ходе проведения Индивидуальных тренировок алкогольные напитки, наркотические вещества, курить.<br/>
                    3.4.4. Посещать Индивидуальные тренировки в состоянии алкогольного или иного опьянения, при наличии остаточного состояния алкогольного или иного опьянения.<br/>
                    3.5. Клиент вправе:<br/>
                    3.5.1. Требовать от Исполнителя предоставления Услуг в соответствии с условиями Договора.<br/>
                    3.5.2. Получать необходимую и достоверную информацию об оказываемых услугах. <br/><br/>
                    <p className="doc-title">4. Порядок оказания услуг</p>
                    4.1.Оказание услуг по Договору осуществляется на основании выбранных Клиентом Услуги или Пакета Услуг, а также Тарифа.<br/>
                    4.2. Порядок осуществления Online тренировок (составления Плана тренировок):<br/>
                    4.2.1. Клиент заполняет анкету, размещенную на Сайте.<br/>
                    4.2.2. Исполнитель перед составлением Плана тренировок проводит бесплатную консультацию продолжительностью в 15-45 минут, осуществляет сбор данных о Клиенте.<br/>
                    4.2.3. Клиент должен своевременно пройти регистрацию на платформе, указанной Исполнителем, для получения доступа к Плану тренировок. В случае возникновения технических сбоев на указанных платформах, Исполнитель вправе направить План тренировок через согласованный с Клиентом мессенджер.<br/>
                    4.2.4. Все программы Online тренировок (План тренировок) разрабатываются Исполнителем и предоставляются Клиенту не позднее 2 (двух) рабочих дней после оплаты.<br/>
                    4.2.5. Клиент должен своевременно и самостоятельно предоставлять данные, полученные в ходе самостоятельных тренировок, Исполнителю в соответствии с выбранным Тарифом.<br/>
                    4.2.6. Исполнитель осуществляет своевременный анализ данных Клиента, и, в случае необходимости, вносит корректировки в План тренировок.<br/>
                    4.2.7. Клиент осуществляет регулярный мониторинг изменений Плана тренировок, предоставленных Исполнителем.<br/>
                    4.3. Порядок проведения Персональных тренировок:<br/>
                    4.3.1. Чтобы заказать Персональную тренировку (Пакет Услуг), Клиент должен оставить заявку на Сайте путем заполнения формы обратной связи, либо связаться с Исполнителем лично. После чего – произвести оплату назначенной тренировки в соответствии с Тарифом, размещенным на Сайте.  Оплата может осуществляться путем перечисления денежных средств:<br/>по реквизитам, указанным в разделе 11 настоящего Договора;<br/> через сервис приема платежей Юкаssа.<br/>
                    4.3.1.1. При выборе оплаты через сервис приема платежей Юкаssа Плательщику (Клиенту или иному лицу, осуществляющему оплату стоимости Услуг за Клиента) необходимо на Сайте нажать на иконку «Payment» в правом верхнем углу, после выбрать необходимую Услугу. После чего осуществляется автоматизированное перенаправление на WEB-страницу платежного сервиса, на которой необходимо ввести следующие данные: номер Банковской карты Держателя карты; Код CVC2/CVV2; Срок действия Банковской карты Держателя карты; Имя и фамилия Держателя карты, указанные на Карте, телефон и e-mail клиента. Комиссия за оплату Услуг с Клиента не взимается.<br/>
                    4.3.1.2. Если данные введены верно, то при нажатии «Оплатить» выводится сообщение об успешном завершении Операции. При несоответствии информации выдается предупреждение о необходимости проверить корректность введенных данных. После выводится Сообщение об оплате и на указанный Клиентом e-mail отправляется чек. Чек может попасть в папку «Спам», Клиент несет самостоятельную ответственность за своевременную проверку всех входящих сообщений, включая папку «Спам».<br/>
                    4.4.2. В случае отказа Клиента от Персональной тренировки менее, чем за 6 часов до времени её проведения (если другое не оговорено Исполнителем заранее), оплата за тренировку не возвращается. Исполнитель не несёт ответственности за действия Клиента, а также за результат этих действий, в том числе в случае причинения этими действиями вреда жизни и/или здоровью Клиента либо других людей.<br/>
                    4.4.3. На любых тренировках: плавательных тренировках, велотренировках, беговых тренировках (включая, но не ограничиваясь) Клиент лично несёт ответственность за соблюдение правил техники безопасности, а также за свою жизнь и здоровье. Исполнитель не несёт ответственности за действия Клиента, а также за результат этих и любых других действий, в том числе в случае причинения этими действиями вреда жизни и/или здоровью Клиента либо других людей.<br/>
                    4.4.4. В случае не присутствия Клиента, а также в случае опоздания более чем на 20 минут и невозможности посещения без уведомления ранее, чем за 2 часа до назначенной тренировки, оплата стоимости не возвращается.   <br/>
                    4.4.5. В случае отмены Исполнителем Индивидуальной тренировки в установленный п. 4.4.2. срок оплата возвращается в полном объёме на счёт Клиента в течение 14 суток. Либо Клиент вправе по желанию согласовать новую дату и время проведения тренировки.<br/><br/>
                    <p className="doc-title">5. Стоимость услуг и порядок расчетов по Договору</p>
                    5.1. Стоимость Услуг, Пакета Услуг по Договору определяется в соответствии с  действующими на момент оплаты Тарифами, размещенными на Сайте.<br/>
                    5.2. Исполнитель имеет право изменять Тарифы в одностороннем порядке с последующим уведомлением Клиента посредством размещения информации на Сайте.<br/>
                    5.3. Стоимость Услуг в соответствии с Тарифами оплачивается Клиентом самостоятельно.<br/>
                    5.3.1.  Выбранная Услуга оплачивается банковской картой онлайн в установленные п. 5.7. настоящего Договора сроки. Для оплаты необходимо перейти в раздел Payment.<br/>
                    5.3.2. Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО «Сбербанк России» для ввода реквизитов Вашей карты. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.<br/>
                    В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard Secure Code для проведения платежа также может потребоваться ввод специального пароля. Способы и возможность получения паролей для совершения интернет-платежей Вы можете уточнить в банке, выпустившем карту.<br/>
                    5.4. Договор считается заключенным после получения денежных средств Исполнителем за соответствующий период оказания Услуг.<br/>
                    5.5. Оплата Online тренировок (Плана тренировок) каждого следующего месяца должна осуществляться в срок не позднее седьмого дня нового месяца. Клиент направляет копию квитанции об оплате на электронную почту Исполнителя: mail@baruzdin-tri.ru или в один из согласованных с Исполнителем мессенджеров не позднее седьмого дня нового месяца. В случае отсутствия у Исполнителя подтверждения оплаты, оказание Услуг может быть приостановлено до момента такого подтверждения, при этом возврат денежных средств за период такой приостановки не осуществляется, а услуги считаются оказанными надлежащим образом.<br/>
                    5.6. По просьбе Клиента Исполнитель может осуществить перерасчет стоимости Online тренировок (Пакета тренировок) и Иных услуг. Клиент обязан назвать причины необходимости осуществления такого перерасчета. Исполнитель вправе отказать в осуществлении перерасчета. <br/>
                    5.7. Оплата Персональных тренировок осуществляется путём предоплаты в размере 100% от стоимости Услуги в срок не позднее двенадцати часов до начала Персональной тренировки. <br/>
                    5.8. В связи с тем, что результат Услуг по Договору носит нематериальный характер и выражается в организации тренировочного процесса, акт об оказании Услуг между Клиентом и Исполнителем не подписывается. Доказательствами надлежащего оказания Исполнителем Услуг является отсутствие письменных претензий со стороны Клиента в течение трех дней после оказания Услуги.<br/>
                    5.9. После оказания любых Услуг денежные средства не возвращаются. <br/><br/>
                    <p className="doc-title">6. Расторжение Договора и его последствия</p>
                    6.1. Настоящий Договор считается расторгнутым: <br/>
                    − по соглашению Клиента и Исполнителя; <br/>
                    − в случае невнесения Клиентом очередного платежа за оказание Услуг. <br/>
                    – после исполнения Исполнителем всех оплаченных Клиентом Услуг <br/>
                    – по инициативе Клиента или Исполнителя. При этом одностороннее расторжение Договора осуществляется путем письменного уведомления (либо уведомления по электронной почте)  второй стороны об одностороннем отказе от исполнения настоящего Договора, но не раньше, чем будут исполнены все ранее достигнутые договоренности и произведены финансовые расчеты.<br/>
                    6.2. Договор может быть расторгнут по инициативе Исполнителя в следующих случаях:<br/>
                    6.2.1. В случае неисполнения Клиентом требования п. 3.4 данного Договора или иных нарушений требований настоящего Договора.<br/>
                    6.2.2. В случае наличия у Клиента противопоказаний для занятия спортом.<br/>
                    6.2.3. Исполнитель в 5-дневный срок извещает Клиента о расторжении Договора. Денежные средства, оплаченные Клиентом за оказание Услуг, в случае расторжения Договора по инициативе Клиента в случаях, указанных в п. 8.2. настоящего Договора, не возвращаются.<br/>
                    6.3  Договор, в случае одностороннего отказа Клиента от его исполнения без уважительных причин, непосещении тренировок, не уведомлении, ненадлежащем уведомлении Исполнителя о его расторжении – считается расторгнутым по инициативе Клиента с момента окончания оплаченного периода оказания Услуг. Внесенные денежные средства в этом случае Клиенту не возвращаются.<br/><br/>
                    <p className="doc-title">7. Ответственность сторон</p>
                    7.1. Клиент полностью принимает на себя ответственность за состояние своего здоровья. Исполнитель не несет ответственность за вред, связанный с любым ухудшением здоровья Клиента, и/или травмами, явившимися результатом или полученными в результате оказания Услуг.<br/>
                    7.2. Заключая данный Договор, Клиент соглашается с тем, что он не вправе требовать от Исполнителя какой-либо компенсации морального, материального вреда или вреда, причиненного здоровью Клиента, как в течение срока действия Договора, так и по истечении срока его действия, за исключением случаев, прямо предусмотренных действующим законодательством Российской Федерации.<br/>
                    7.3. В случае ненадлежащего исполнения Договора (как со стороны Клиента, так и со стороны Исполнителя), повлекшего неблагоприятные последствия для другой стороны, ответственность наступает согласно действующему законодательству Российской Федерации.<br/><br/>
                    <p className="doc-title">8. Обстоятельства непреодолимой силы</p>
                    8.1. Стороны не несут ответственности за неисполнение либо ненадлежащее исполнение обязательств по настоящему договору, если докажут, что это произошло вследствие наступления обстоятельств непреодолимой силы (форс-мажор), которые стороны не могли ни предвидеть, ни предотвратить. К форс-мажорным обстоятельствам относятся, в частности: военные действия, пожар, стихийные бедствия, военные операции любого характера, блокады, запрет на экспорт или импорт, забастовки, гражданские волнения, задержки вследствие аварии или неблагоприятных погодных условий, эпидемии, пандемии, чрезвычайные ситуации, в том числе в сфере здравоохранения.<br/>
                    8.2. При наступлении обстоятельств, указанных в пункте 8.1 настоящего Договора, сторона должна в течение 5 (пяти) рабочих дней известить о них в письменном виде другую сторону.<br/>
                    8.3. Извещение должно содержать данные о характере обстоятельств, документы, подтверждающие наступление указанных обстоятельств.<br/>
                    8.4. В случае наступления обстоятельств, предусмотренных в пункте 8.1 Договора, срок выполнения стороной обязательств отодвигается соразмерно времени, в течение которого действуют эти обстоятельства и их последствия.<br/>
                    8.5. Если наступившие обстоятельства, перечисленные в пункте 8.1 Договора, и их последствия продолжают действовать более двух месяцев, стороны проводят дополнительные переговоры для выявления альтернативных способов исполнения настоящего договора.<br/><br/>
                    <p className="doc-title">9. Порядок разрешения споров</p>
                    9.1. Все неурегулированные споры, возникающие между сторонами в ходе исполнения договора, предварительно разрешаются в досудебном порядке путем предъявления письменных требований (претензий).<br/>
                    9.2. Претензия составляется в письменной форме за подписью уполномоченного лица и направляется «Почтой России» ценным письмом с описью вложения либо курьерской службой с распиской о вручении претензии представителю другой стороны.<br/>
                    9.3. Направляемая претензия должна содержать:<br/>
                    – четко сформулированные требования к нарушившей стороне;<br/>
                    – обстоятельства, на которые ссылается сторона в качестве обоснования своих требований;<br/>
                    – доказательства, подтверждающие обстоятельства, со ссылкой на нормы права;<br/>
                    – расчет суммы штрафов, пеней и других мер ответственности;<br/>
                    – описание прилагаемых к претензии документов;<br/>
                    – иные сведения, которые соответствующая сторона посчитает нужным включить в претензию.<br/>
                    В случае направления претензии без указанной информации и документов претензия считается ненаправленной, а досудебный порядок – несоблюденным.<br/>
                    9.4. В случае неурегулирования сторонами разногласий в претензионном порядке, а также в случае неполучения письменного ответа на претензию в течение 30 (тридцати) календарных дней с даты направления претензии второй стороне спор передается на рассмотрение суду, компетентному согласно правилам подсудности рассматривать возникший спор.<br/><br/>
                    <p className="doc-title">10. Дополнительные условия</p>
                    10.1. Исполнитель настоятельно рекомендует прохождение медицинского осмотра у лечащего или спортивного врача перед началом любых занятий и исполнением любых рекомендаций в рамках оказания любых услуг Исполнителем.<br/>
                    10.2. Соглашаясь с условиями Договора Клиент подтверждает, что ознакомился с условиями настоящего Договора, описанием Услуг, Пакетами Услуг, Тарифами и ценами за оказание Услуг. Клиент имел возможность задать все интересующие вопросы по содержанию указанных документов и получить на них ответы от Исполнителя.<br/>
                    10.4. Клиент дает свое согласие на сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, уничтожение следующих персональных данных, предоставленных Исполнителю при заключении настоящего Договора: фамилия, имя, дата рождения, номер телефона, иные данные, передаваемые Исполнителю при заполнении анкеты. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.<br/>
                    10.5. Ни одна из сторон по настоящему Договору не имеет права передавать права и обязательства по данному Договору третьей стороне без письменного согласия другой стороны. После подписания настоящего Договора все предшествующие переговоры и переписка по данному договору теряют силу.<br/>
                    10.4. Во всем остальном, что не урегулировано настоящим Договором, стороны руководствуются действующим законодательством Российской Федерации.<br/><br/>
                    <p className="doc-title">11. Реквизиты Исполнителя</p>
                    Баруздин Дмитрий Эдуардович<br/>
                    Паспорт 4014171719<br/>
                    ИНН 781103860352<br/>
                    Телефон: 8 (911) 158-54-77<br/>
                    E-mail: dimabar208@gmail.com<br/>
                    Номер счёта: 40817810355867714029<br/>
                    Банк получателя: СЕВЕРО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК<br/>
                    Корр. счёт: 30101810500000000653<br/>
                    БИК: 044030653<br/>
                    Почтовый адрес банка: 191124, САНКТ-ПЕТЕРБУРГ, УЛ. КРАСНОГО ТЕКСТИЛЬЩИКА, 2<br/>
                    Почтовый адрес доп.офиса: 192177, Г. САНКТ-ПЕТЕРБУРГ, УЛ. ПРИБРЕЖНАЯ, Д. 8, ЛИТ. А, КОРП. 1, ПОМ. 1Н<br/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Offer;