import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import '../legal/legal.css'

function Privacy() {
    return (  
        <div className="generall_page_wrapper">
            <div className="main-content">
                <div className="any-page"><Header/></div>
                    <div className="container doc-style">
                        <p className="doc-title_main">Политика обработки персональных данных</p>
                        <br/>
                        1. Общие положения
                        Использование сервисов веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a> означает безоговорочное согласие Пользователя с&nbsp;настоящей Политикой обработки персональных данных (далее&nbsp;&mdash; Политика) и&nbsp;указанными в&nbsp;ней условиями обработки его персональных данных; в&nbsp;случае несогласия с&nbsp;этими условиями Пользователь должен воздержаться от&nbsp;использования сервисов.<br/>
                        <br/>
                        Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями Федерального закона от&nbsp;27.07.2006. &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; (далее&nbsp;&mdash; Закон о&nbsp;персональных данных) и&nbsp;определяет порядок обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности персональных данных, предпринимаемые Баруздиным Дмитрием Эдуардовичем (далее&nbsp;&mdash; Оператор). <br/>
                        1.1. Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав и&nbsp;свобод человека и&nbsp;гражданина при обработке его персональных данных, в&nbsp;том числе защиты прав на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну.<br/>
                        1.2. Настоящая Политика Оператора применяется ко&nbsp;всей информации, которую Оператор может получить о&nbsp;посетителях веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.
                        1.3. Оператор не&nbsp;контролирует и&nbsp;не&nbsp;несет ответственности за&nbsp;сайты третьих лиц, на&nbsp;которые Пользователь может перейти по&nbsp;ссылкам, доступным на&nbsp;веб-сайте <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.<br/>
                        <br/><br/>
                        <p className="doc-title">2. Основные понятия, используемые в&nbsp;Политике.</p>
                        2.1. Автоматизированная обработка персональных данных&nbsp;&mdash; обработка персональных данных с&nbsp;помощью средств вычислительной техники.
                        2.2. Блокирование персональных данных&nbsp;&mdash; временное прекращение обработки персональных данных (за&nbsp;исключением случаев, если обработка необходима для уточнения персональных данных).
                        2.3. Веб-сайт&nbsp;&mdash; совокупность графических и&nbsp;информационных материалов, а&nbsp;также программ для ЭВМ и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети интернет по&nbsp;сетевому адресу <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.<br/>
                        2.4. Информационная система персональных данных&nbsp;&mdash; совокупность содержащихся в&nbsp;базах данных персональных данных и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий и&nbsp;технических средств.<br/>
                        2.5. Обезличивание персональных данных&nbsp;&mdash; действия, в&nbsp;результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.<br/>
                        2.6. Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br/>
                        2.7. Оператор&nbsp;&mdash; государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с&nbsp;другими лицами организующие и/или осуществляющие обработку персональных данных, а&nbsp;также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными данными.
                        2.8. Персональные данные&nbsp;&mdash; любая информация, относящаяся прямо или косвенно к&nbsp;определенному или определяемому Пользователю веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.<br/>
                        2.9. Персональные данные, разрешенные субъектом персональных данных для распространения,&nbsp;&mdash; персональные данные, доступ неограниченного круга лиц к&nbsp;которым предоставлен субъектом персональных данных путем дачи согласия на&nbsp;обработку персональных данных, разрешенных субъектом персональных данных для распространения в&nbsp;порядке, предусмотренном Законом о&nbsp;персональных данных (далее&nbsp;&mdash; персональные данные, разрешенные для распространения).<br/>
                        2.10. Пользователь&nbsp;&mdash; любой посетитель веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.
                        2.11. Предоставление персональных данных&nbsp;&mdash; действия, направленные на&nbsp;раскрытие персональных данных определенному лицу или определенному кругу лиц.<br/>
                        2.12. Распространение персональных данных&nbsp;&mdash; любые действия, направленные на&nbsp;раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на&nbsp;ознакомление с&nbsp;персональными данными неограниченного круга лиц, в&nbsp;том числе обнародование персональных данных в&nbsp;средствах массовой информации, размещение в&nbsp;информационно-телекоммуникационных сетях или предоставление доступа к&nbsp;персональным данным каким-либо иным способом.<br/>
                        2.13. Трансграничная передача персональных данных&nbsp;&mdash; передача персональных данных на&nbsp;территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
                        2.14. Уничтожение персональных данных&nbsp;&mdash; любые действия, в&nbsp;результате которых персональные данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего восстановления содержания персональных данных в&nbsp;информационной системе персональных данных и/или уничтожаются материальные носители персональных данных. <br/>
                        <br/><br/>
                        <p className="doc-title">3. Предмет политики обработки персональных данных</p>
                        3.1. Настоящая Политика устанавливает обязательства Оператора по&nbsp;неразглашению и&nbsp;обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет Оператору при заполнении специальной формы (анкеты) на&nbsp;веб-сайте <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>, через которую Пользователь направляет запрос на&nbsp;получение обратной связи от&nbsp;Оператора по&nbsp;вопросу предоставления услуги. <br/>
                        3.2. Персональные данные, разрешённые к&nbsp;обработке в&nbsp;рамках настоящей Политики, включают в&nbsp;себя следующую информацию: <br/>
                        3.2.1. фамилия, имя, год рождения Пользователя; <br/>
                        3.2.2. контактный телефон Пользователя; <br/>
                        3.2.3. биометрические персональные данные (рост, вес); <br/>
                        3.3. Любая иная персональная информация неоговоренная выше (информация из&nbsp;cookies, используемые браузеры и&nbsp;операционные системы и&nbsp;т.д.) подлежит надежному хранению и&nbsp;нераспространению, за&nbsp;исключением случаев, предусмотренных в&nbsp;п.п.&nbsp;5.4. и&nbsp;5.7. настоящей Политики. <br/>
                        <br/><br/>
                        <p className="doc-title">4. Цели сбора персональных данных Пользователя</p>
                        4.1. Оператор собирает и&nbsp;хранит только те&nbsp;персональные данные, которые необходимы для предоставления сервисов или исполнения соглашений и&nbsp;договоров с&nbsp;Пользователем, за&nbsp;исключением случаев, когда законодательством предусмотрено обязательное хранение персональной данных в&nbsp;течение определенного законом срока. <br/>
                        4.2. Персональные данные Пользователя Оператор обрабатывает в&nbsp;следующих целях:
                        4.2.1. Предоставление Пользователю доступа к&nbsp;персонализированным ресурсам веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>.<br/>
                        4.2.2. Установление с&nbsp;Пользователем обратной связи, включая направление уведомлений, запросов, в&nbsp;том числе касающихся использования веб-сайта <a href="https://baruzdin-tri.ru">https://baruzdin-tri.ru</a>, оказание услуг, обработку запросов и&nbsp;заявок от&nbsp;Пользователя. <br/>
                        4.2.3. Предоставления Пользователю эффективной клиентской и&nbsp;технической поддержки при возникновении проблем, связанных с&nbsp;исполнением определенного соглашения или договора с&nbsp;Пользователем&nbsp;/ использованием веб-сайта <a href="https://baruzdin.online">https://baruzdin.online</a>.<br/>
                        <br/><br/>
                        <p className="doc-title">5. Условия обработки персональных данных Пользователей и&nbsp;их&nbsp;передачи третьим лицам</p>
                        Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и&nbsp;технических мер, необходимых для выполнения в&nbsp;полном объеме требований действующего законодательства в&nbsp;области защиты персональных данных. <br/>
                        5.1. Обработка персональных данных осуществляется с&nbsp;согласия субъекта персональных данных на&nbsp;обработку его персональных данных. <br/>
                        5.2. В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может актуализировать их&nbsp;самостоятельно, путем направления Оператору уведомления на&nbsp;адрес электронной почты tri@baruzdin.online с&nbsp;пометкой &laquo;Актуализация персональных данных&raquo;. <br/>
                        5.3. В&nbsp;отношении персональных данных Пользователя сохраняется их&nbsp;конфиденциальность, кроме случаев добровольного предоставления Пользователем данных о&nbsp;себе для общего доступа неограниченному кругу лиц. <br/>
                        5.4. Оператор вправе передать персональные данные Пользователя третьим лицам в&nbsp;следующих случаях: <br/>
                        5.4.1. Пользователь выразил согласие на&nbsp;такие действия. <br/>
                        5.4.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с&nbsp;Пользователем. <br/>
                        5.4.3. Передача предусмотрена российским или иным применимым законодательством в&nbsp;рамках установленной законодательством процедуры. <br/>
                        5.5. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не&nbsp;предусмотрен договором или действующим законодательством. <br/>
                        Пользователь может в&nbsp;любой момент отозвать свое согласие на&nbsp;обработку персональных данных, направив Оператору уведомление посредством электронной почты на&nbsp;электронный адрес Оператора tri@baruzdin.online с&nbsp;пометкой &laquo;Отзыв согласия на&nbsp;обработку персональных данных&raquo;. <br/>
                        5.6. Вся информация, которая собирается сторонними сервисами, в&nbsp;том числе платежными системами, средствами связи и&nbsp;другими поставщиками услуг, хранится и&nbsp;обрабатывается указанными лицами (Операторами) в&nbsp;соответствии с&nbsp;их&nbsp;Пользовательским соглашением и&nbsp;Политикой обработки персональных данных (конфиденциальности). Оператор не&nbsp;несет ответственность за&nbsp;действия третьих лиц, в&nbsp;том числе указанных в&nbsp;настоящем пункте поставщиков услуг. <br/>
                        5.7. При утрате или разглашении персональных данных Оператор информирует Пользователя об&nbsp;утрате или разглашении персональных данных. <br/>
                        5.8. Оператор принимает необходимые организационные и&nbsp;технические меры для защиты персональных данных Пользователя от&nbsp;неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а&nbsp;также от&nbsp;иных неправомерных действий третьих лиц. <br/>
                        5.9. Оператор совместно с&nbsp;Пользователем принимает все необходимые меры по&nbsp;предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя. <br/>
                        <br/><br/>
                        <p className="doc-title">6. Основные права и&nbsp;обязанности Оператора</p>
                        6.1. Оператор имеет право: <br/>
                        &mdash;&nbsp;получать от&nbsp;субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные; <br/>
                        &mdash;&nbsp;в&nbsp;случае отзыва субъектом персональных данных согласия на&nbsp;обработку персональных данных, а&nbsp;также, направления обращения с&nbsp;требованием о&nbsp;прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в&nbsp;Законе о&nbsp;персональных данных; <br/>
                        &mdash;&nbsp;самостоятельно определять состав и&nbsp;перечень мер, необходимых и&nbsp;достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о&nbsp;персональных данных и&nbsp;принятыми в&nbsp;соответствии с&nbsp;ним нормативными правовыми актами, если иное не&nbsp;предусмотрено Законом о&nbsp;персональных данных или другими федеральными законами. <br/>
                        6.2. Оператор обязан: <br/>
                        &mdash;&nbsp;предоставлять субъекту персональных данных по&nbsp;его просьбе информацию, касающуюся обработки его персональных данных; <br/>
                        &mdash;&nbsp;организовывать обработку персональных данных в&nbsp;порядке, установленном действующим законодательством РФ; <br/>
                        &mdash;&nbsp;отвечать на&nbsp;обращения и&nbsp;запросы субъектов персональных данных и&nbsp;их&nbsp;законных представителей в&nbsp;соответствии с&nbsp;требованиями Закона о&nbsp;персональных данных;
                        &mdash;&nbsp;сообщать в&nbsp;уполномоченный орган по&nbsp;защите прав субъектов персональных данных по&nbsp;запросу этого органа необходимую информацию в&nbsp;течение 10&nbsp;дней с&nbsp;даты получения такого запроса; <br/>
                        &mdash;&nbsp;публиковать или иным образом обеспечивать неограниченный доступ к&nbsp;настоящей Политике в&nbsp;отношении обработки персональных данных; <br/>
                        &mdash;&nbsp;принимать правовые, организационные и&nbsp;технические меры для защиты персональных данных от&nbsp;неправомерного или случайного доступа к&nbsp;ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а&nbsp;также от&nbsp;иных неправомерных действий в&nbsp;отношении персональных данных; <br/>
                        &mdash;&nbsp;прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и&nbsp;уничтожить персональные данные в&nbsp;порядке и&nbsp;случаях, предусмотренных Законом о&nbsp;персональных данных; <br/>
                        &mdash;&nbsp;исполнять иные обязанности, предусмотренные Законом о&nbsp;персональных данных.<br/>
                        <br/><br/>
                        <p className="doc-title">7. Основные права и&nbsp;обязанности субъектов персональных данных</p>
                        7.1. Субъекты персональных данных имеют право: <br/>
                        &mdash;&nbsp;получать информацию, касающуюся обработки его персональных данных, за&nbsp;исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в&nbsp;доступной форме, и&nbsp;в&nbsp;них не&nbsp;должны содержаться персональные данные, относящиеся к&nbsp;другим субъектам персональных данных, за&nbsp;исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и&nbsp;порядок ее&nbsp;получения установлен Законом о&nbsp;персональных данных; <br/>
                        &mdash;&nbsp;требовать от&nbsp;Оператора уточнения его персональных данных, их&nbsp;блокирования или уничтожения в&nbsp;случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не&nbsp;являются необходимыми для заявленной цели обработки, а&nbsp;также принимать предусмотренные законом меры по&nbsp;защите своих прав; <br/>
                        &mdash;&nbsp;выдвигать условие предварительного согласия при обработке персональных данных в&nbsp;целях продвижения на&nbsp;рынке товаров, работ и&nbsp;услуг; <br/>
                        &mdash;&nbsp;на&nbsp;отзыв согласия на&nbsp;обработку персональных данных, а&nbsp;также, на&nbsp;направление требования о&nbsp;прекращении обработки персональных данных; <br/>
                        &mdash;&nbsp;обжаловать в&nbsp;уполномоченный орган по&nbsp;защите прав субъектов персональных данных или в&nbsp;судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных; <br/>
                        &mdash;&nbsp;на&nbsp;осуществление иных прав, предусмотренных законодательством РФ. <br/>
                        7.2. Субъекты персональных данных обязаны: <br/>
                        &mdash;&nbsp;предоставлять Оператору достоверные данные о&nbsp;себе; <br/>
                        &mdash;&nbsp;сообщать Оператору об&nbsp;уточнении (обновлении, изменении) своих персональных данных. <br/>
                        7.3. Лица, передавшие Оператору недостоверные сведения о&nbsp;себе, либо сведения о&nbsp;другом субъекте персональных данных без согласия последнего, несут ответственность в&nbsp;соответствии с&nbsp;законодательством РФ. <br/>
                        <br/><br/>
                        <p className="doc-title">8. Разрешение споров</p>
                        8.1. До&nbsp;обращения в&nbsp;суд с&nbsp;иском по&nbsp;спорам, возникающим из&nbsp;отношений между Пользователем веб-сайта <a>https://baruzdin-tri.ru</a> и&nbsp;Оператором, обязательным является предъявление претензии (письменного предложения о&nbsp;добровольном урегулировании спора). <br/>
                        8.2. Получатель претензии в&nbsp;течение 30&nbsp;календарных дней со&nbsp;дня получения претензии, письменно уведомляет заявителя претензии о&nbsp;результатах рассмотрения претензии. <br/>
                        8.3. При не&nbsp;достижении соглашения спор будет передан на&nbsp;рассмотрение в&nbsp;судебный орган в&nbsp;соответствии с&nbsp;действующим законодательством Российской Федерации. <br/>
                        8.4. К&nbsp;настоящей Политике и&nbsp;отношениям между Пользователем и&nbsp;Оператором применяется действующее законодательство Российской Федерации. <br/>
                        <br/><br/>
                        <p className="doc-title">9. Заключительные положения</p>
                        9.1. Оператор вправе вносить изменения в&nbsp;настоящую Политику без согласия Пользователя. <br/>
                        9.2. Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам, касающимся обработки его персональных данных, направив Оператору соответствующий запрос по&nbsp;электронной почте tri@baruzdin.online. <br/>
                        9.3. Новая Политика обработки персональных данных вступает в&nbsp;силу с&nbsp;момента ее&nbsp;размещения на&nbsp;Сайте, если иное не&nbsp;предусмотрено новой редакцией Политики. <br/>
                        9.4. В&nbsp;данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до&nbsp;замены ее&nbsp;новой версией. <br/>
                        9.5. Актуальная версия Политики размещена в&nbsp;свободном доступе в&nbsp;сети Интернет по&nbsp;адресу <a>https://baruzdin-tri.ru/privacy</a>/.<br/>               
                    </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Privacy;